import { MenuItem } from '../models/menu.model';

// menu items for vertcal and detached layout
const MENU_ITEMS: MenuItem[] = [
    { key: 'navigation', label: 'Navigation', isTitle: true },
    // {
    //     key: 'ds-dashboard-1',
    //     label: 'Dashboard 1',
    //     link: '/dashboard-2',

    // },
    { key: 'dashboard', prod: true, label: 'Dashboard', isTitle: false, icon: 'airplay', link: '/dashboard', allowedRoles: ['admin', 'dspmanager'] },
    // { key: 'media-buying', prod: true, label: 'Media Buying Table', isTitle: false, icon: 'layout', link: '/media-buying', allowedRoles: ['admin'] },
    {
        key: 'apps-contacts',
        label: 'Contacts',
        isTitle: false,
        icon: 'book',
        collapsed: true,
        children: [
            { key: 'contacts-list', label: 'Members List', link: '/apps/contacts/list', parentKey: 'apps-contacts' },
            { key: 'contacts-profile', label: 'Profile', link: '/apps/contacts/profile', parentKey: 'apps-contacts' },
        ],
    },

    { key: 'tickets-list', prod: true, label: 'Tickets', isTitle: false, icon: 'aperture', link: '/tickets/list', allowedRoles: ['admin', 'dspmanager'] },
    { key: 'customers', prod: true, label: 'Customers', isTitle: false, icon: 'users', link: '/customers', allowedRoles: ['admin'] },
    { key: 'traffic-estimator', prod: true, label: 'Traffic Estimator', isTitle: false, icon: 'bar-chart', link: '/traffic', allowedRoles: ['admin', 'dspmanager', 'user'] },

    // { key: 'prelanders', prod: true, label: 'Prelanders', isTitle: false, icon: 'grid', link: '/prelanders', allowedRoles: ['admin', 'dspmanager', 'user'] },
    {
        key: 'landing_pages',
        label: 'Landing Pages',
        isTitle: false,
        prod:true,
        allowedRoles: ['admin', 'dspmanager'],
        icon: 'book',
        collapsed: true,
        children: [
            { key: 'offers', label: 'Offers', link: '/landing-pages/offers', parentKey: 'landing_pages' }
        ],
    },
    {
        key: 'automations',
        prod: true,
        allowedRoles: ['admin', 'dspmanager','dspuser'],
        label: 'Automations',
        isTitle: false,
        icon: 'cpu',
        collapsed: true,
        children: [
            {
                key: 'epc-adjusting',
                label: 'EPC Adjusting',
                link: '/automations/epc-adjusting',
                parentKey: 'automations',
            },
            {
                key: 'pubsub-whitelister',
                label: 'PubSub Whitelister',
                link: '/automations/pubsub-whitelister',
                allowedRoles: ['admin', 'dspmanager','dspuser'],
                parentKey: 'automations',
            },
            {
                key: 'tag-grader',
                label: 'Tag Grader',
                link: '/automations/tag-grader',
                allowedRoles: ['admin', 'dspmanager'],
                parentKey: 'automations',
            },
            {
                key: 'creative-optimizer',
                label: 'Creative Optimizer',
                link: '/automations/creative-optimizer',
                allowedRoles: ['admin', 'dspmanager','dspuser'],
                parentKey: 'automations',
            },
            {
                key: 'remote-feeds',
                label: 'Remote Feeds',
                link: '/automations/remote-feeds',
                parentKey: 'automations',
            },
            {
                key: 'super-whitelist',
                label: 'Super WhiteList',
                link: '/automations/super-whitelist',
                allowedRoles: ['admin', 'dspmanager'],
                parentKey: 'automations',
            },
            {
                key: 'blacklist',
                label: 'Blacklist Checker',
                allowedRoles: ['admin', 'dspmanager'],
                link: '/automations/blacklist-checker',
                parentKey: 'automations',
            },
            
        ],
    },
    {
        key: 'reports',
        prod: true,
        allowedRoles: ['admin', 'dspmanager'],
        label: 'Performence',
        isTitle: false,
        icon: 'columns',
        collapsed: true,
        children: [
            {
                key: 'affise',
                label: 'Affise',
                link: '/reports/affise',
                parentKey: 'reports',
            },
            {
                key: 'adwora-epc',
                label: 'Adwora EPC',
                link: '/reports/adwora-epc',
                parentKey: 'reports',
            }
        ],
    },
    {
        key: 'system',
        prod: true,
        allowedRoles: ['admin'],
        label: 'System',
        isTitle: false,
        icon: 'tool',
        collapsed: true,
        children: [
            {
                key: 'settings',
                label: 'Settings',
                link: '/system/settings/traffic-estimator',
                parentKey: 'system',
            },
            {
                key: 'users',
                label: 'Users',
                link: '/users',
                parentKey: 'system',
            },
        ],
    }
];

export { MENU_ITEMS };